<template>
  <transition
    enter-active-class="duration-300 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="transform opacity-0"
  >
    <div class="backdrop" v-show="show">
      <div class="modal">
        <h2>You solved the puzzle!</h2>
        <p>It took you {{ moves.toLocaleString() }} moves!</p>

        <hr>

        <button type="button" @click.prevent="$emit('close')">
          Play Again
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'WinModal',
  props: {
    moves: Number,
    show: Boolean,
  },
  watch: {
    show(val) {
      if (val) window.addEventListener('keydown', this.close)
      else window.removeEventListener('keydown', this.close)
    },
  },
  methods: {
    close(e) {
      if (e.key === 'Enter' || e.key === 'Escape' || e.key === ' ') {
        this.$emit('close')
      }
    },
  },
}
</script>

<style scoped>
.backdrop { @apply
  absolute top-0 left-0 right-0 bottom-0
  w-screen h-screen
  flex flex-col justify-center items-center
  bg-black bg-opacity-50
;}

.modal { @apply
  w-80
  mx-auto p-6
  bg-white
  rounded-lg
  text-center
;}

h2 { @apply
  text-2xl font-semibold text-slate-800
;}

p { @apply
  my-4
;}

hr { @apply
  my-6
 border-slate-200
}

button { @apply
  w-full
  px-5 py-2.5
  bg-blue-500 hover:bg-blue-700 active:bg-blue-800
  text-lg font-semibold text-white
  rounded-lg
;}

</style>
