<template>
  <div class="board">
    <div
      :class="`square ${square.value ? 'filled' : ''} ${ square.class }`"
      v-for="(square, index) in squares" :key="index"
      @click="move(index)"
    >
      <span>{{ square.value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameBoard',
  data: () => ({
    grid: 4,
    squares: [],
  }),
  mounted() {
    window.addEventListener('keydown', this.handleArrowKey)

    this.setup()
  },
  methods: {
    setup() {
      this.squares = Array(this.grid * this.grid)
        .fill(null)
        .map((_, i) => ({ value: i + 1, class: '' }))
      this.squares[this.squares.length - 1].value = null

      this.shuffle()
    },
    move(index, animate = true) {
      // current square
      const row = Math.floor(index / this.grid)
      const col = index % this.grid

      // find the empty square
      const emptySquare = this.squares.find(square => square.value === null)
      const emptyIndex = this.squares.indexOf(emptySquare)
      const emptyRow = Math.floor(emptyIndex / this.grid)
      const emptyCol = emptyIndex % this.grid

      let direction = ''
      let diff = 0

      if (row === emptyRow) {
        direction = col > emptyCol ? 'right' : 'left'
        diff = col > emptyCol ? 1 : -1
      } else if (col === emptyCol) {
        direction = row > emptyRow ? 'up' : 'down'
        diff = row > emptyRow ? this.grid : -this.grid
      }

      if (direction === '') return

      for (let i = emptyIndex; i !== index; i += diff) {
        this.squares[i].value = this.squares[i + diff].value
        this.squares[i].class = animate ? direction : ''
      }
      this.squares[index].value = null

      if (!animate) return

      setTimeout(() => {
        this.squares.forEach(square => {
          square.class = ''
        })
      }, 1)

      this.$emit('move')

      if (this.check()) this.$emit('win')
    },
    check() {
      for (let i = 0; i < this.squares.length - 1; i++) {
        if (this.squares[i].value !== i + 1) return false
      }

      return true
    },
    shuffle() {
      for (let i = 0; i < 500; i++) {
        this._shuffleMove()
      }

      while (this.squares[this.squares.length - 1].value !== null) {
        this.move(Math.floor(Math.random() * this.squares.length), false)
      }
    },
    _shuffleMove() {
      const emptySquare = this.squares.find(square => square.value === null)
      const emptyIndex = this.squares.indexOf(emptySquare)
      const emptyRow = Math.floor(emptyIndex / this.grid)
      const emptyCol = emptyIndex % this.grid

      let index = 0
      let options = new Array(this.grid)
        .fill(null)
        .map((_, i) => i)

      if (Math.random() < 0.5) {
        // Move row
        options.splice(options.indexOf(emptyCol), 1)
        index = Math.floor(Math.random() * options.length) + (emptyRow * this.grid)
      } else {
        // Move column
        options.splice(options.indexOf(emptyRow), 1)
        index = options[Math.floor(Math.random() * options.length)] * this.grid + emptyCol
      }

      this.move(index, false)
    },
    handleArrowKey(e) {
      const emptySquare = this.squares.find(square => square.value === null)
      const emptyIndex = this.squares.indexOf(emptySquare)

      switch (e.key) {
        case 'ArrowUp':
          if (emptyIndex + this.grid < this.squares.length)
            this.move(emptyIndex + this.grid)
          break

        case 'ArrowDown':
          if (emptyIndex - this.grid >= 0)
            this.move(emptyIndex - this.grid)
          break

        case 'ArrowLeft':
          if ((emptyIndex + 1) % this.grid > 0)
            this.move(emptyIndex + 1)
          break

        case 'ArrowRight':
          if ((emptyIndex - 1) % this.grid >= 0)
            this.move(emptyIndex - 1)
          break
      }
    }
  },
}
</script>

<style scoped>
.board { @apply
  max-w-lg
  my-4 p-2
  grid grid-cols-4 gap-2
  border-4 border-white rounded-lg
  bg-black bg-opacity-10
;}

.square { @apply
  w-20 h-20
  transform-gpu translate-x-0 translate-y-0
  transition-transform duration-75
  select-none
;
  -webkit-tap-highlight-color: transparent;
}

.square.up { @apply
  transform translate-y-full
  transition-none
;}
.square.down { @apply
  transform -translate-y-full
  transition-none
;}
.square.left { @apply
  transform -translate-x-full
  transition-none
;}
.square.right { @apply
  transform translate-x-full
  transition-none
;}

.square.filled { @apply
  relative
  border-4 border-white rounded-lg
  bg-black bg-opacity-25 hover:bg-opacity-30 active:bg-opacity-50
  cursor-pointer
;}

.square.filled > span { @apply
  absolute top-1/2 left-1/2
  transform -translate-x-1/2 -translate-y-1/2
  text-4xl font-semibold text-white
;}
</style>
