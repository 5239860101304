<template>
  <div class="frame">
    <div class="title">
      <h1 class="font-semibold">
        <span class="font-light mr-2">15</span>
        <span class="font-semibold">Puzzle</span>
      </h1>

      <span class="font-light text-right">
        {{ moves.toLocaleString() }} Move{{ moves === 1 ? '' : 's' }}
      </span>
    </div>

    <game-board
      ref="game"
      @move="moves += 1" @win="modal = true"
    />

    <div class="grid grid-cols-2 gap-4">
      <div class="text-block">
        <label>Number of Games</label>
        {{ scores.length.toLocaleString() }}
      </div>

      <div class="text-block">
        <label>Average Moves</label>
        {{ averageMoves.toLocaleString() }}
      </div>
    </div>

    <win-modal :moves="moves" :show="modal" @close="close" />
  </div>
</template>

<script>
import GameBoard from './components/GameBoard.vue'
import WinModal from './components/WinModal.vue'

export default {
  name: 'App',
  components: {
    GameBoard,
    WinModal
  },
  data: () => ({
    moves: 0,
    modal: false,
    scores: [],
  }),
  computed: {
    averageMoves() {
      if (this.scores.length === 0) return 0

      const rawAvg = this.scores.reduce((acc, score) => acc + score, 0) / this.scores.length
      return rawAvg.toFixed(2)
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.scores = JSON.parse(localStorage.getItem('p15_scores')) || []
    },
    save() {
      localStorage.setItem('p15_scores', JSON.stringify(this.scores))
    },
    close() {
      this.modal = false

      this.scores.push(this.moves)
      this.save()

      this.moves = 0
      this.$refs.game.setup()
    },
  }
}
</script>

<style>
#app { @apply
  w-screen min-h-screen
  flex flex-col justify-around items-center
  bg-gradient-to-br from-blue-600 to-pink-600
  select-none
;
}

.frame { @apply
  max-w-lg
;}

.title { @apply
  flex flex-row justify-between items-center
  mb-6
  text-white text-2xl
;}

.btn { @apply
  text-lg font-medium text-white
  rounded-lg
;}

.text-block { @apply
  py-2
  bg-black bg-opacity-25
  text-center text-white
  rounded-lg
;}

.text-block > label { @apply
  block
  text-sm font-bold
;}
</style>
